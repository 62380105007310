import { graphql, useStaticQuery } from 'gatsby';
import { DirectusScottishAddressService, ScottishAddressServices } from './scottish-address-services.interface';

export default (): ScottishAddressServices => {
    const { directusScottishAddressService } = useStaticQuery<DirectusScottishAddressService>(graphql`
        query {
            directusScottishAddressService {
                image {
                    data {
                        full_url
                    }
                    width
                    height
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 830) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                }
                registered_office_heading
                registered_office_price {
                    value
                }
                registered_office_link
                service_address_heading
                service_address_price {
                    value
                }
                service_address_link
                business_address_heading
                business_address_price {
                    value
                }
                business_address_link
            }
        }
    `);

    directusScottishAddressService.services = [];
    directusScottishAddressService.services.push({
        heading: directusScottishAddressService.registered_office_heading,
        price: directusScottishAddressService.registered_office_price,
        link: directusScottishAddressService.registered_office_link,
    });
    directusScottishAddressService.services.push({
        heading: directusScottishAddressService.service_address_heading,
        price: directusScottishAddressService.service_address_price,
        link: directusScottishAddressService.service_address_link,
    });
    directusScottishAddressService.services.push({
        heading: directusScottishAddressService.business_address_heading,
        price: directusScottishAddressService.business_address_price,
        link: directusScottishAddressService.business_address_link,
    });

    return directusScottishAddressService;
};
