import React from 'react';
import slugify from 'react-slugify';
import Img from 'gatsby-image';

import Button from 'components/common/button/Button';
import ButtonGroup from 'components/common/button-group/ButtonGroup';
import Markdown from 'components/common/markdown/Markdown';
import PriceWithoutVat from 'components/common/price-without-vat/PriceWithoutVat';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import scottishAddressServicesHook from 'hooks/scottish-address-services/scottish-address-services.hook';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import { getFieldBySlug } from 'utils/directus';

import s from './ScottishAddressServices.scss';

export default (): JSX.Element => {
    const { node: section } = getFieldBySlug('scottish-address-services', sectionTitleHook());
    const scottishAddressServices = scottishAddressServicesHook();

    return (
        <div className={s.scottishAddressServices}>
            <SectionTitle title={section.heading} align="center" />
            <div className={s.scottishAddressServices__image}>
                <Img fadeIn fluid={scottishAddressServices.image.localFile.childImageSharp.fluid}/>
            </div>
            <div className={s.scottishAddressServices__services}>
                {scottishAddressServices.services.map((service) => (
                    <div key={`scottish-${slugify(service.heading)}`} className={s.scottishAddressServices__service}>
                        <h3 className={s.scottishAddressServices__heading}>
                            <Markdown source={service.heading} />
                        </h3>
                        <p className={s.scottishAddressServices__price}>
                            <PriceWithoutVat price={service.price.value} /> per year
                        </p>
                        <p className={s.scottishAddressServices__discounted}>(60% OFF)</p>
                        <ButtonGroup>
                            <Button
                                role="secondary"
                                size="large"
                                to={service.link}
                            >
                                Learn More
                            </Button>
                        </ButtonGroup>
                    </div>
                ))}
            </div>
        </div>
    );
};
