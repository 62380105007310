import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import Section from 'components/common/section/Section';
import { Wrapper } from 'components/common/wrapper/Wrapper';
import CtaPanel from 'components/directus/cta-panel/CtaPanel';
import BankAdsGrid from 'components/directus/bank-ads-grid/BankAdsGrid';
import CustomerReviews from 'components/directus/customer-reviews/CustomerReviews';
import Navigation from 'components/directus/navigation/Navigation';
import Hero from 'components/directus/hero/Hero';
import HeroBoxes from 'components/directus/hero-boxes/HeroBoxes';
import HeroNamecheck from 'components/directus/hero-namecheck/HeroNamecheck';
import HomepageFaqs from 'components/directus/homepage-faqs/HomepageFaqs';
import PackageGridScottish from 'components/directus/package-grid/scottish-company-formation';
import ScottishAddressServices from 'components/directus/scottish-address-services/ScottishAddressServices';
import UniqueSellingPoints from 'components/directus/unique-selling-points/UniqueSellingPoints';
import { DirectusPageData } from 'interfaces/directus-page-data.interface';
import { getFieldBySlug } from 'utils/directus';
import { PageProps } from 'utils/gatsby.interface';

export default ({ data }: PageProps<DirectusPageData>): JSX.Element => {
    const slug = 'scottish-company-formation';
    const { node } = getFieldBySlug('scottish-company-formation', data.allDirectusPage.edges);

    return (
        <>
            <Helmet titleTemplate="">
                <title>{node.title}</title>
                <meta name="title" content={node.title} />
                <meta name="description" content={node.description} />
                <meta name="robots" content={node.robots} />
                <link rel="canonical" href={node.canonical} />
            </Helmet>
            <Hero slug={slug}>
                <Navigation isHome />
                <HeroNamecheck slug={slug} />
                <HeroBoxes slug={slug} />
            </Hero>

            <UniqueSellingPoints slug={slug} />

            <PackageGridScottish />

            <Wrapper>
                <Section isHome>
                    <ScottishAddressServices />
                </Section>
                <Section isHome>
                    <CustomerReviews />
                </Section>
                <Section isHome>
                    <BankAdsGrid isHome />
                </Section>
                <Section isHome>
                    <CtaPanel slug="scottish-cta" />
                </Section>
                <Section isHome>
                    <HomepageFaqs slug={slug} />
                </Section>
            </Wrapper>
        </>
    );
};

export const query = graphql`
  query {
    allDirectusPage(filter: { slug: { eq: "scottish-company-formation" } }) {
      edges {
        node {
          title
          slug
          description
          robots
          canonical
        }
      }
    }
  }
`;
